<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0 user_manage-accounts"
    >
      <div class="table-filters">
        <div class="row">
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Rater ID
            </v-subheader>
            <v-text-field
              v-model="filterRaterID"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Ratee ID
            </v-subheader>
            <v-text-field
              v-model="filterRateeID"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Status
            </v-subheader>
            <v-select
              v-model="filterStatus"
              :items="[
                { name: 'Empty', value: 'empty' },
                { name: 'Open', value: 'open' },
                { name: 'Closed', value: 'closed' },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Rating
            </v-subheader>
            <v-select
              v-model="filterRating"
              multiple
              :items="[
                { name: 'Rating 5', value: '5' },
                { name: 'Rating 4', value: '4' },
                { name: 'Rating 3', value: '3' },
                { name: 'Rating 2', value: '2' },
                { name: 'Rating 1', value: '1' },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
        </div>
        <div class="table-filters__controls">
          <v-btn
            color="red"
            medium
            @click="clearFilters"
          >
            Reset
          </v-btn>
        </div>
      </div>
      <div
        class="table-wrapper px-6 py-3"
      >
        <v-data-table
          ref="ratingstable"
          v-model="selected"
          :page.sync="currentPage"
          :headers="ratingHeaders"
          :items="ratings"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [1, 2, 10, 20, 50, 100]
          }"
          :loading="loading"
          :items-per-page.sync="itemsPerPage"
          :fixed-header="true"
          class="fixed-columns-table"
          @page-count="pageCount"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.id="{ item }">
            <a
              href="#"
              target="_blank"
              class="link__hidden full-cell"
            >
              {{ item.id }}
            </a>
          </template>
          <template v-slot:item.raterID="{ item }">
            <a
              href="#"
              target="_blank"
              class="link__hidden full-cell"
            >
              {{ item.raterID }}
            </a>
          </template>
          <template v-slot:item.rateeID="{ item }">
            <a
              href="#"
              target="_blank"
              class="link__hidden full-cell"
            >
              {{ item.rateeID }}
            </a>
          </template>
          <template v-slot:item.requestID="{ item }">
            <a
              href="#"
              target="_blank"
              class="link__hidden full-cell"
            >
              {{ item.requestID }}
            </a>
          </template>
          <template v-slot:item.objectID="{ item }">
            <a
              href="#"
              target="_blank"
              class="link__hidden full-cell"
            >
              {{ item.objectID }}
            </a>
          </template>
          <template v-slot:item.modifiedById="{ item }">
            <a
              href="#"
              target="_blank"
              class="link__hidden full-cell"
            >
              {{ item.modifiedById }}
            </a>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="table-actions">
              <v-btn
                color="teal lighten-2"
                elevation="0"
                class="action-btn"
                @click="$router.push('/users/user-ratings/' + item.id)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
              </v-btn>

              <v-btn
                color="red"
                elevation="0"
                class="action-btn"
                @click="deleteRating(item.id)"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-snackbar
      v-model="showAlertMessage"
      :color="alertColor"
      timeout="3500"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'
  export default {
    data () {
      return {
        currentPage: 1,
        pageCount: 1,
        itemsPerPage: 10,
        selected: [],
        filterRaterID: '',
        filterRateeID: '',
        filterStatus: null,
        filterRating: '',
        appliedFilters: {},
        ratingsTotal: 0,
        message: '',
        showAlertMessage: false,
        alertColor: '',
        loading: false,
        ratingHeaders: [
          { text: 'Rating ID', value: 'id', align: 'center', width: '110px' },
          { text: 'Rater ID', value: 'raterID', align: 'center', width: '110px' },
          { text: 'Ratee ID', value: 'rateeID', align: 'center', width: '110px' },
          { text: 'Request ID', value: 'requestID', align: 'center', width: '120px' },
          { text: 'Object ID', value: 'objectID', align: 'center', width: '110px' },
          { text: 'Modified By ID', value: 'modifiedById', align: 'center', width: '130px' },
          { text: 'Rating', value: 'rating', align: 'center', width: '90px' },
          { text: 'Comment', value: 'comment', width: '300px' },
          { text: 'Rating Status', value: 'ratingStatus', width: '300px' },
          { text: 'Created', value: 'created', width: '200px' },
          { text: 'Rating Date', value: 'ratingDate', width: '200px' },
          { text: 'Action', value: 'action', align: 'center', width: '80px' },
        ],
        ratings: [],
      }
    },

    watch: {
      filterRaterID () { this.fetchRatings() },
      filterStatus () { this.fetchRatings() },
      filterRating () { this.fetchRatings() },
      itemsPerPage () { this.fetchRatings() },
      currentPage () { this.fetchRatings() },
    },

    created () {
      this.fetchRatings()
    },

    methods: {
      fetchRatings () {
        const _this = this
        _this.loading = true
        const filterStatus = this.filterStatus === 'empty' ? '' : this.filterStatus
        const filterRating = this.filterRating.length > 0 ? this.filterRating.map(Number) : null
        axios.get('/admin/ratings/users', {
          params: {
            perPage: this.itemsPerPage,
            page: this.currentPage,
            ratedBy: this.filterRaterID || null,
            userId: this.filterRateeID || null,
            status: filterStatus,
            rating: filterRating,
          },
        })
          .then(response => {
            const data = response.data

            _this.ratings = data.ratingsUser.map(rating => {
              return {
                id: rating.id,
                raterID: rating.ratedBy.id,
                rateeID: rating.user.id,
                requestID: rating.rentingRequestId,
                objectID: rating.objectId,
                modifiedById: rating.modifiedById,
                rating: rating.rating,
                comment: rating.comment,
                ratingStatus: rating.status,
                created: rating.created,
                ratingDate: rating.ratedAt,
              }
            })

            const total = data.ratingsTotal
            _this.pageCount = Math.ceil(total / _this.itemsPerPage)
            if (_this.pageCount && _this.currentPage > _this.pageCount) {
              _this.currentPage = _this.pageCount
            }
            _this.loading = false
          })
          .catch(() => {
            _this.message = 'An error occurred while fetching data'
            _this.showAlertMessage = true
            _this.alertColor = 'error'
            _this.loading = false
          })
      },
      deleteRating (id) {
        if (!confirm('Are you sure you want to delete this rating?')) {
          return
        }

        const _this = this
        axios.get(`/admin/ratings/users/delete/${id}`)
          .then(() => {
            _this.message = 'Rating deleted successfully'
            _this.showAlertMessage = true
            _this.alertColor = 'success'
            _this.fetchRatings()
          })
          .catch(() => {
            _this.message = 'An error occurred while deleting rating'
            _this.showAlertMessage = true
            _this.alertColor = 'error'
          })
      },
      clearFilters () {
        this.filterUserId = null
        this.filterRating = []
        this.filterRaterID = null
        this.filterStatus = null
      },
    },
  }
</script>

<style lang="scss">
.fixed-columns-table .v-data-table__wrapper table th:nth-child(2),
.fixed-columns-table .v-data-table__wrapper table td:nth-child(2) {
  left: calc(110px) !important;
}
</style>
